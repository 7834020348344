interface TrackingParams {
  [key: string]: string;
}

export class TrackingService {
  private static readonly TRACKING_URL = "https://ads.octagon-lab.com/click";

  private static getSystemInfo() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const isDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)",
    ).matches;

    return {
      timezone,
      screenWidth,
      screenHeight,
      interfaceStyle: isDarkMode ? "dark" : "light",
    };
  }

  private static buildTrackingUrl(params: TrackingParams): URL {
    const url = new URL(this.TRACKING_URL);
    const systemInfo = this.getSystemInfo();

    // Add URL parameters
    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.append(key, value);
    });

    // Add system info
    url.searchParams.append("timezone", systemInfo.timezone);
    url.searchParams.append("screen_width", systemInfo.screenWidth.toString());
    url.searchParams.append("screen_height", systemInfo.screenHeight.toString());
    url.searchParams.append("interface_style", systemInfo.interfaceStyle);

    return url;
  }

  public static async sendTrackingRequest(
    params: TrackingParams,
  ): Promise<Response> {
    const trackingUrl = this.buildTrackingUrl(params);

    try {
      return await fetch(trackingUrl.toString(), {
        method: "GET",
        redirect: "manual",
      });
    } catch (error) {
      console.error("Error sending tracking request:", error);
      throw error;
    }
  }
}
