export const containerVariants = {
    hidden: {opacity: 0},
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.2
        }
    }
};

export const fadeInLeftVariant = {
    initial: {opacity: 0, x: -20},
    animate: {opacity: 1, x: 0},
    transition: {duration: 0.8, ease: "easeOut"}
};